module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Autobedrijf Boks","short_name":"Boks","start_url":"/?pwa=true","background_color":"#fff","theme_color":"#e1140d","display":"minimal-ui","icon":"src/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"f38c75f52b38edbd425fb72e45bfb05b"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://2052e3f24a5c4ff1afe974afa3acbcf8@o1430407.ingest.sentry.io/6781455","tracesSampleRate":1,"release":"gatsby@2024-12-14T11:52:33.937Z","environment":"production","enabled":true,"debug":false},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-breakpoints/gatsby-browser.js'),
      options: {"plugins":[],"queries":{"sm":"(min-width: 36rem)","md":"(min-width: 48rem)","lg":"(min-width: 62rem)","xl":"(min-width: 75rem)"}},
    },{
      plugin: require('../../../packages/gatsby-theme-menus/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../packages/gatsby-theme-apolloclient/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../themes/acm/gatsby-browser.js'),
      options: {"plugins":[],"core":{"sourceStrapi":{"multiSite":"1","themes":{"vehicles":true,"vehicleLease":true,"employees":true,"rating":true,"documents":true,"pagebuilder":true,"searchagent":true}}}},
    },{
      plugin: require('../../../packages/gatsby-theme-pagebuilder/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"includeInDevelopment":false,"id":"GTM-5RHGTN3","defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-NV35BFLNQB","UA-6533313-2"],"gtagConfig":{"anonymize_ip":true},"pluginConfig":{"head":false,"respectDNT":true,"exclude":[],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0}},
    },{
      plugin: require('../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
